import './style.scss';
import { showOrderModal } from '../../popups/v1/OrderModal/scripts'

export default !function () {
  const block = document.querySelector('.gift-course')

  if (!block) {
    return
  }

  const button = document.querySelector('.gift-course__button');

  if (button) {
    const blockName = block.closest('[data-block-name]')?.dataset.blockName || 'Блок без названия'
    const buttonType = button.dataset.actionButton || (button.dataset.redirectLink && 'redirect')

    button.addEventListener('click', () => {
      if (buttonType) {
        if (buttonType === 'order-modal') {
          const blockName = button.closest('[data-block-name]')?.dataset.blockName ?? 'Блок без названия'
          showOrderModal({ blockName })
        }

        window.$analytics?.push('eng-gift-click')
        window.$analytics?.push('all-block-consult-button-click', {
          blockName,
          buttonType,
          buttonText: button.textContent
        })
      }
    })
  }
}();
